<template>
  <section>
    <h1 style="font-weight:600;">My Workflows</h1>
    <div class="vue-data-table-default">
      <div>
        <data-tables-server
          :data="allWorkflows"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :pagination-props="paginationPropsForTable"
          :total="total"
          style="width: 100%"
          v-loading="loading"
          v-if="!getIsMobile"
        >
          <el-table-column label="Name" width="250">
            <template slot-scope="scope">
              <i class="el-icon-refresh"></i>
              <span
                style="margin-left: 10px"
                @click="viewWorkflow(scope.row.documents[0].document_id._id)"
              >{{ scope.row.name }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Actions" width="200">
            <template slot-scope="scope">
              <el-tooltip content="View Workflow">
                <el-button
                  plain
                  type="primary"
                  size="mini"
                  @click="viewWorkflow(scope.row.documents[0].document_id._id)"
                >
                  <i class="el-icon-view"></i>
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column label="Status" width="120">
            <template slot-scope="scope" id="category">
              <div class="d-flex activestatus">
                <div :class="scope.row.status.toLowerCase() + ' circle'"></div>
                <div>
                  <p v-if="scope.row.status == 'ACTIVE'">Active</p>
                  <p v-else-if="scope.row.status == 'INACTIVE'">Inactive</p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Created at" width="270">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">
                {{
                scope.row.created_at | globalDateTimeFormat
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Updated at" width="270">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">
                {{
                scope.row.updated_at | globalDateTimeFormat
                }}
              </span>
            </template>
          </el-table-column>
        </data-tables-server>
      </div>
    </div>
    <data-tables-server
      :data="allWorkflows"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      :pagination-props="paginationPropsForTable"
      :total="total"
      style="width: 100%"
      v-loading="loading"
      v-if="getIsMobile"
      class="workflows-list-mobile"
    >
      <el-table-column label="Name" width="250">
        <template slot-scope="scope">
          <i class="el-icon-refresh"></i>
          <span
            style="margin-left: 10px"
            @click="viewWorkflow(scope.row.documents[0].document_id._id)"
          >{{ scope.row.name }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Actions">
        <template slot-scope="scope">
          <el-tooltip content="View Workflow">
            <el-button
              plain
              type="primary"
              size="mini"
              @click="viewWorkflow(scope.row.documents[0].document_id._id)"
            >
              <i class="el-icon-view"></i>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column label="Status" width="170">
        <template slot-scope="scope" id="category">
          <div class="d-flex activestatus">
            <div :class="scope.row.status.toLowerCase() + ' circle'"></div>
            <div>
              <p v-if="scope.row.status == 'ACTIVE'">Active</p>
              <p v-else-if="scope.row.status == 'INACTIVE'">Inactive</p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column type="expand">
        <template slot-scope="scope">
          <div class="expand-column-item">
            <div class="expand-column-item-label">Created at:</div>
            <div class="expand-column-item-content">
              <template v-if="scope.row.created_at">
                <i class="el-icon-time"></i>
                <span style="margin-left: 10px">
                  {{
                  scope.row.created_at | globalDateTimeFormat
                  }}
                </span>
              </template>
            </div>
          </div>
          <div class="expand-column-item">
            <div class="expand-column-item-label">Updated at:</div>
            <div class="expand-column-item-content">
              <template v-if="scope.row.updated_at">
                <i class="el-icon-time"></i>
                <span style="margin-left: 10px">
                  {{
                  scope.row.updated_at | globalDateTimeFormat
                  }}
                </span>
              </template>
            </div>
          </div>
        </template>
      </el-table-column>
    </data-tables-server>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      allWorkflows: [],
      pageSize: 0,
      total: 0,
      currentPage: 1,
      page: 1,
      popUpText: "View Workflow",
      loading: false
    };
  },
  computed: {
    ...mapGetters("applicationUsers", ["getUserWorkflows"]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    paginationProps() {
      return [5, 10, 20, 50];
    },
    paginationPropsForTable() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50]
      };
    }
  },
  async mounted() {
    this.loading = true;
    this.allWorkflows = [];
    await this.$store.dispatch("applicationUsers/fetchUserWorkflows");
    if (this.getUserWorkflows?.data) {
      this.allWorkflows = this.getUserWorkflows.data;
    }
    this.total = this.getUserWorkflows.data.length;
    this.loading = false;
  },
  methods: {
    viewWorkflow(id) {
      this.$router.push({
        name: "employee-documents-custom-document-pp",
        params: {
          employee_document_id: id
        }
      });
    }
  }
};
</script>
<style scoped>
.circle {
  margin-right: 4px;
  margin-top: 6px;
  height: 8px;
  width: 8px;
  display: inline-block;
  border-radius: 50%;
  background: green;
}
</style>
